<template>
    <div class="container">
        <intro title="Fees" :content="content" id="intro"/>
        <div class="content">
            <price-table v-for="procedure in Procedures['categories']" :key="procedure.category" :procedure="procedure" class="procudures">
            </price-table>
        </div>
        
    </div>
</template>

<script>
import  Procedures  from "../assets/json/procedures.json";
import Intro from '../components/Intro.vue'
import PriceTable from "../components/PriceTable.vue";
export default {
    data(){
        return{
            Procedures,
            SelectedProcedure: null,       
            content: ["Our dental treatment is offered at a high standard of care and you will find a list of the treatment we offer. Depending on your needs this will be tailored and discussed at depth with your dentist.", "We also offer a membership plan (Denplan), which could possibly spread the cost of your treatment over a monthly fee. Your dentist will be able to provide you with further information on this."]
       }
    },
        components: {
        Intro,
        PriceTable
    },
        methods:{
        toggleOptions(){
            this.optionsExpand = ! this.optionsExpand
        }
    }
}
</script>

<style scoped>
    .content{
        padding: 1rem 1.5rem;
        background-color: var(--primary-variant);
    }  

    @media screen and (min-width: 768px){
        .content{
            padding: 1rem 4rem;
        }        
    }
    @media screen and (min-width: 1024px){        
        .content{
            padding: var(--padding1);
            cursor: default;
        }        
    }
    @media screen and (min-width: 1440px) {
        .content{
            padding: 4rem 8rem;
        }
    } 
</style>